import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

// import { useI18n } from 'vue-i18n'
// const { t } = useI18n({ useScope: 'global' })

export const successToast = (title = '', description = '', position = 'bottom-right') => {
  createToast({ title, description }, {type: 'success', position})
}
export const dangerToast = (title = '', description = '', position = 'bottom-right') => {
  createToast({ title, description }, {type: 'danger', position})
}
export const infoToast = (title = '', description = '', position = 'bottom-right') => {
  createToast({ title, description }, {type: 'info', position})
}
export const warningToast = (title = '', description = '', position = 'bottom-right') => {
  createToast({ title, description }, {type: 'warning', position})
}

export const copiedToClipboardToast = () => {
  infoToast('Cкопійовано у буфер обміну')
}
export const saveChangesToast = () => {
  infoToast('Зміни збережено', 'А от чи вірно ви ввели дані? ;)')
}
export const concurrentUpdateToast = () => {
  dangerToast("Спробуйте ще раз :)", "Даний запис було відредаговано іншим користувачем")
}
